import { ChannelSourcePojo, VirtualChannelSourcePojo } from '@tivio/sdk-react'
import React, { createContext, useState, PropsWithChildren } from 'react'

interface ScrollPositions {
	[rowIndex: number]: number
}

type Source = string | VirtualChannelSourcePojo | ChannelSourcePojo | null | undefined

interface AppContextProps {
	source: Source
	setSource: (source: Source) => void
}

export const SourceContext = createContext<AppContextProps>({} as AppContextProps)

export const SourceProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	const [source, setSource] = useState<Source>(null)
	return (
		<SourceContext.Provider value={{ source, setSource }}>
			{children}
		</SourceContext.Provider>
	)
}
