import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { LangCode, TivioProvider, useChannelSource } from '@tivio/sdk-react'
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom'
import Root from './routes/Root'
import Tivio from './routes/Tivio'
import { ErrorScreen } from './components/ErrorScreen'
import 'modern-normalize/modern-normalize.css'
import { FocusRoot } from '@please/lrud'
import { AppProvider } from './context/appContext'
import VirtualChannel from './routes/VirtualChannel'
import { SourceProvider } from './context/sourceContext'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
)

function App() {

    const router = createBrowserRouter([
        {
            path: '/virtual-channel/*',
            element: <VirtualChannel />
        },
        {
            path: '/',
            element: <Root />
            ,
            errorElement: <ErrorScreen />,
        },
        {
            path: '/tivio/*',
            element: <Tivio />,
        },
    ])

    return (
        <>
            <FocusRoot>
                <TivioProvider conf={{
                    secret: 'ZuUF7JGu8Poqy7E8k5fx',
                    language: LangCode.CS,
                    currency: 'CZK',
                    // @ts-ignore
                    // bundleUrlOverride: 'http://localhost:3000/core-react-dom-bundle/index.js',
                }}>
                    <SourceProvider>
                        <RouterProvider router={router} />
                    </SourceProvider>
                </TivioProvider>
            </FocusRoot>
        </>
    )
}

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
