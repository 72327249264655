import { useState, useEffect, useContext } from 'react'
import { ChannelSourcePojo, VirtualChannelSourcePojo, useChannelSource, useTivioData } from '@tivio/sdk-react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { SourceContext } from '../context/sourceContext'


export default function VirtualChannel() {
    const bundle = useTivioData()
    const { source: tivioSource } = useChannelSource(
        'IxLi19VRasgu94bIaXbH',
    )
    const { source } = useContext(SourceContext)

    const x = bundle?.hooks?.useTvChannel('IxLi19VRasgu94bIaXbH')

    const navigate = useNavigate()

    const [showBackButton, setShowBackButton] = useState(false)
    let timeoutId: ReturnType<typeof setTimeout> | null = null



    const handleBack = () => {
        navigate('/')
    }

    const handleMouseMove = () => {
        setShowBackButton(true)
        if (timeoutId !== null) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            setShowBackButton(false)
        }, 2700)
    }

    const handleKeydown = (event: any) => {
        if (event.key === 'Backspace' || event.key === 'Escape') {
          handleBack();
        }
      };


    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('keydown', handleKeydown)
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('keydown', handleKeydown)
        }
    }, [])
    if (!bundle?.components?.WebPlayer || (!source && !tivioSource) || !bundle?.hooks?.useTvChannel) {
        return (
            <div className={'w-screen h-screen bg-black'} />
        )
    }

    return (
        <div
            className={'w-screen h-screen'}
            onMouseMove={handleMouseMove}
        >
            <div
                className={`absolute top-[3%] left-[4%] z-[9999] cursor-pointer group transition-all duration-300 ${showBackButton ? 'opacity-100' : 'opacity-0'} hover:opacity-100`}
                onClick={handleBack}>
                <BsArrowLeftShort className={'text-white w-10 h-10 group-hover:text-gray-300 '} />
            </div>
            <bundle.components.ChannelContext.Provider value={x?.tvChannel ?? undefined}>
                <bundle.components.WebPlayer
                    id="player1"
                    className="web-player"
                    source={source ?? tivioSource}
                />
            </bundle.components.ChannelContext.Provider>
        </div>
    )
}
